import React from 'react'
import FlexCard from './flexCard'
import './flexCardRow.scss'

const FlexCardRow = (props) => {
  const { arrayCards } = props


  return (
    <div className="box">
      {arrayCards.map((cardData) =>
        <FlexCard key={cardData.link} link={cardData.link} text={cardData.titleText} backgroundImg={cardData.backgroundImg.url} />
      )}
    </div>
  )
}

export default FlexCardRow
