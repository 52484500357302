import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

export default function Service(props) {
    const { services } = props

    const data = useStaticQuery(graphql`
    query ServiceQuery {
      site {
        siteMetadata {
          siteUrl
          title
          phoneNumber
          faxNumber
          email
          addressRegion
          postalCode
          addressCountry
          streetAddress
          sameAs
          imageUrl
          foundingDate
          legalName
        }
      }
    }
  `)

    const serviceOffers = () => {
        let itemArray = [];
        for (let index = 0; index < services.length; index++) {
            itemArray.push({ "@type": "Offer", "itemOffered": { "@type": "Service", "name": services[index].titleText } });
        }
        return itemArray
    }

    const schema = {
        "@context": "https://schema.org/",
        "@type": "Service",
        "serviceType": "Commercial Repair Services",
        "provider": {
            "@type": "LocalBusiness",
            "name": data.site.siteMetadata.title,
            "image": data.site.siteMetadata.imageUrl,
            "address": {
                "@type": "PostalAddress",
                "addressLocality": data.site.siteMetadata.addressLocality,
                "addressRegion": data.site.siteMetadata.addressRegion,
                "postalCode": data.site.siteMetadata.postalCode,
                "addressCountry": data.site.siteMetadata.addressCountry,
                "streetAddress": data.site.siteMetadata.streetAddress
            },
            "telephone": data.site.siteMetadata.phoneNumber
        },
        "areaServed": [
            {
                "@type": "State",
                "name": "US-OH",
                "sameAs": "https://en.wikipedia.org/wiki/Ohio",
            },
            {
                "@type": "State",
                "name": "US-KY",
                "sameAs": "https://en.wikipedia.org/wiki/Kentucky",
            },
            {
                "@type": "State",
                "name": "US-IN",
                "sameAs": "https://en.wikipedia.org/wiki/Indiana",
            }
        ],
        "hasOfferCatalog": {
            "@type": "OfferCatalog",
            "name": "Repair Services",
            "itemListElement": serviceOffers()
        }
    }

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    )
}


