
import React from 'react'

const FlexCard = (props) => {
    const { link, text, backgroundImg } = props
    const cardBacground = {
        backgroundImage: `url(${backgroundImg})`
    };

    return (
        <a style={cardBacground} className="outer-image" href={link}>
                <div className="hover-background"></div>
                <div className="inner-text">
                    {text}
                </div>
        </a>
    )
}

export default FlexCard
