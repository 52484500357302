import React from 'react'
import './reviewStars.scss'


const ReviewStars = (props) => {
    const { stars } = props

    const starsFilled = () => {
        if (stars === 1) {
            return (
                <div className='stars'>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>                    
                </div>
            )
        } else if (stars === 2) {
            return (
                <div className='stars'>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>    
                </div>
            )
        } else if (stars === 3) {
            return (
                <div className='stars'>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                </div>
            )
        } else if (stars === 4) {
            return (
                <div className='stars'>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="unchecked" />
                    </svg>
                </div>
            )
        } else if (stars === 5) {
            return (
                <div className='stars'>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                    <svg height="25" width="25" className="star rating">
                        <polygon points="9.9, 1.1, 3.3, 21.78, 19.8, 8.58, 0, 8.58, 16.5, 21.78" className="checked" />
                    </svg>
                </div>
            )
        }

    }

    return (
        <div className='stars'>
            {starsFilled()}
        </div>
    )
}

export default ReviewStars