
import React from 'react'

const FlexHalfContent = (props) => {
    // const { content } = props

    return (
        <div className="boxHalf">
            <div className="boxMargin">
                {/* <h2>{content.title}</h2> */}
                <h2>Proudly Serving the Tri-State Area</h2>
                {/* <p>{content.body}</p> */}
                <p>Valley Commercial Service is pleased to provide reliable 24/7 commercial service for 
                    HVAC, exhaust hoods, and refrigeration throughout Cincinnati, 
                    Northern Kentucky, and the surrounding areas.
                </p>
                {/* <div className="main-button"><a href={content.buttonLink}><strong>{content.buttonText}</strong></a></div> */}
                <div className="main-button"><a href="/service-areas/"><strong>View Service Areas</strong></a></div>
            </div>
        </div>
    )
}

export default FlexHalfContent
