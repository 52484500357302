import React from 'react'
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import './herobanner.scss'


const HeroBanner = (props) => {
    const { img, text, button_text, button_link } = props;

    return (
        <div className='hero-container'>
            <div className='hero-image'>
                <GatsbyImage image={getImage(img)} alt="test" />
            </div>
            <div className="hero-content">
                <h1>
                    {text}
                </h1>
                <div className="main-button"><a href={button_link}><strong>{button_text}</strong></a></div>
            </div>
        </div>
    )
}

export default HeroBanner