
import React from 'react'

const FlexHalfBox = (props) => {
    // const { link, text, backgroundImg } = props
    // const cardBacground = {
    //     backgroundImage: `url(${backgroundImg})`
    // };

    return (
        <div className="boxHalf">
            <img alt="Service Area Map Valley Commercial Services" src="/assets/servicemap.svg"></img>
        </div>
    )
}

export default FlexHalfBox
