import React from 'react'
import Layout from '../components/Layout'
import Head from '../components/Head'
import FlexCardRow from '../components/flexCardRow/flexCardRow'
import FlexHalfRow from '../components/flexHalfBoxes/flexHalfRow'
import HeroBanner from '../components/heroBanner'
import TaglineBreak from '../components/taglineBreak'
import SEOWebpage from "../components/SEO/webpage"
import SEOService from "../components/SEO/service"
import ReviewStars from "../components/ReviewStars"

import { useStaticQuery, graphql } from "gatsby"

export default function Home() {

  const pageData = useStaticQuery(graphql`
    query HomeQuery {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
      graphCmsHomePage {
        serviceCards {
          link
          backgroundImg {
            url
          }
          titleText
        }
        createdAt
        publishedAt
        updatedAt
        title
        heroImage {
          gatsbyImageData(aspectRatio: 2.1, breakpoints: [200, 350, 900, 1600] placeholder: BLURRED, layout: CONSTRAINED)
        }
        heroText
        heroButtonText
        heroButtonLink
        slug
        serviceCardsTitle
        pageHeaderSeo {
          metaDescription
          metaTitle
        }
        reviews {
          reviewName
          reviewContent
          reviewRating
        }
        content {
          html
        }
      }
    }
    `)

  const pageUrl = pageData.site.siteMetadata.siteUrl + pageData.graphCmsHomePage.slug;


  return (
    <Layout>
      <Head
        title={pageData.graphCmsHomePage.pageHeaderSeo.metaTitle}
        pageDesc={pageData.graphCmsHomePage.pageHeaderSeo.metaDescription}
        url={pageUrl}
      />
      <SEOWebpage
        title={pageData.graphCmsHomePage.pageHeaderSeo.metaTitle}
        meta_description={pageData.graphCmsHomePage.pageHeaderSeo.metaDescription}
        url={pageUrl}
        date_published={pageData.graphCmsHomePage.createdAt}
        date_modified={pageData.graphCmsHomePage.updatedAt}
      />
      <div className="pageSectionHome">
        <HeroBanner
          img={pageData.graphCmsHomePage.heroImage}
          text={pageData.graphCmsHomePage.heroText}
          button_text={pageData.graphCmsHomePage.heroButtonText}
          button_link={pageData.graphCmsHomePage.heroButtonLink}
        />
        <FlexHalfRow layout='imageContent'/>
        <TaglineBreak />
        <h2 className="center-text">{pageData.graphCmsHomePage.serviceCardsTitle}</h2>
        <FlexCardRow arrayCards={pageData.graphCmsHomePage.serviceCards} /> 
        <div style={{maxWidth: 300 + 'px', margin: 'auto'}} className="second-button"><a href={'/services/'}><strong>View All Services</strong></a></div>
        <SEOService services={pageData.graphCmsHomePage.serviceCards}/>
        <h2 className="center-text">Reviews</h2>
        <div className="reviews">
          {pageData.graphCmsHomePage.reviews.map((reviewData, index) =>
              <blockquote key={index} className="review">
                <ReviewStars stars={reviewData.reviewRating} />
                {reviewData.reviewContent}
                <cite>-- {reviewData.reviewName}</cite>
              </blockquote>
          )}
        </div>
    </div>
    </Layout>
  )
}
