import React from 'react'
import './taglinebreak.scss'


const TaglineBreak = (props) => {

  return (
    <div className='tagline-container'>
      <div className="taglineContent">
        <h2>24/7 Service Dispatch</h2>
        <p>Problems with Commercial Refrigeration, Heating & Cooling, Ice Machines, Cooking Equipment, Exhaust Hoods can happen at any time, causing your business to experience unwanted downtime.</p>
        <p>Avoid losing customers and money with 24/7 service to get your business running again in no time.</p>
        <div className="tagButton">
          <a href="/contact"><strong>Request Dispatch</strong></a>
        </div>
      </div>
      <div className="taglineImage">
        <picture>
          <source srcSet="/assets/valleyTrucksMobile.jpg" media="(max-width: 767px)" />
          <img src="/assets/valleyTrucks.jpg" alt="Valley Trucks" />
        </picture>
      </div>

    </div>
  )
}




export default TaglineBreak