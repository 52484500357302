import React from 'react'
import FlexHalfBox from './flexHalfBox'
import FlexHalfContent from './flexHalfContent'
import './flexHalfBoxes.scss'

const FlexHalfRow = (props) => {
  const { layout, leftData, rightData } = props

  if (layout === 'imageContent') {
    return (
      <div className="boxHalfRow">
        <FlexHalfBox content={leftData}/>
        <FlexHalfContent content={rightData} />
      </div>
    )
  } else if (layout === 'contentImage') {
    return (
      <div className="boxHalfRow">
        <FlexHalfContent content={leftData}/>
        <FlexHalfBox content={rightData}/>
      </div>
    )
  } else if (layout === 'contentContent') {
    return (
      <div className="boxHalfRow">
        <FlexHalfContent content={leftData}/>
        <FlexHalfContent content={rightData}/>
      </div>
    )
  } else if (layout === 'imageImage') {
    return (
      <div className="boxHalfRow">
        <FlexHalfBox content={leftData}/>
        <FlexHalfBox content={rightData}/>
      </div>
    )
  } else {
    return (
      <div className="boxHalfRow">
        <p>No Layout</p>
      </div>
    )
  }
}

export default FlexHalfRow
